const solutions = () => {
    const slider = document.getElementsByClassName(
        'swiper solutions__slider',
    )[0]
    const pagination =
        slider.parentNode.getElementsByClassName('slider-pagination')[0]
    const navigationNext = slider.parentNode.getElementsByClassName(
        'slider-navigation next',
    )[0]
    const navigationPrev = slider.parentNode.getElementsByClassName(
        'slider-navigation prev',
    )[0]

    // eslint-disable-next-line no-undef
    return new Swiper(slider, {
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,
        initialSlide: 0,
        pagination: {
            el: pagination,
            clickable: true,
        },
        navigation: {
            nextEl: navigationNext,
            prevEl: navigationPrev,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1025: {
                slidesPerView: 3,
            },
        },
    })
}

export default solutions
